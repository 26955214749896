<template>
  <div class="table-component border border-grey-field rounded-lg overflow-x-auto" :class="{ compact: compact }">
    <div class="table-wrapper">
      <table class="w-full border-collapse rounded-lg overflow-hidden">
        <thead class="">
          <tr>
            <td v-for="(column, index) in columns" :key="'columns-' + index" class="whitespace-nowrap font-bold" :class="[column.position ? `text-${column.position}` : 'text-left']">
              {{ column.label }} <span></span>
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-if="list?.length > 0">
            <tr v-for="(entry, idx) in list" :key="'entry-' + idx">
              <td v-for="(column, index) in columns" :key="'columns-' + index" class="align-middle leading-5">
                <slot name="table-row" :entry="entry" :column="column" :index="idx" />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="columns?.length" class="text-center empty">
                <div class="flex justify-center items-center w-full flex-col gap-2">
                  <Empty width="48" height="48" />
                  <p class="">{{ emptyMessage }}</p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Empty: () => import('@/components/Icons/Empty')
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    compact: Boolean,
    emptyMessage: {
      type: String,
      default: 'Data is unavailable.'
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  }
}
</script>
<style lang="scss" scoped>
.table-component {
  min-height: 480px;
  thead {
    tr {
      td {
        background-color: #f7f7f7;
        padding: 16px 16px;
        box-shadow: inset 0px -1px 0px rgba(206, 213, 222, 0.5);
      }
    }
  }
  tbody {
    tr {
      td {
        background-color: #fff;
        padding: 12px 16px;
        font-weight: 500;
        font-size: 14px;
        box-shadow: inset 0px -1px 0px rgba(206, 213, 222, 0.5);
      }
      &:last-child td {
        box-shadow: none;
      }
    }
  }
}
.row {
  &-item {
    &:last-child {
      .shadow-border-b {
        // box-shadow: none;
      }
    }
  }
}
.compact {
  min-height: 0 !important;
}
.empty {
  height: 400px;
}
</style>
